import React, { useState } from "react";
import "./slider.scss";

const App = () => {
  return (
    <div id="main" className="w-screen h-screen overflow-y-scroll">
      <body className="py-2">
        <div className="slider">
          <div className="slide-track">
            {[...Array(40)].map((_, index) => (
              <div className="slide">
                <img className="w-40 aspect-auto" src="https://media.tenor.com/nOij19s2b8gAAAAi/polar-bear-walking.gif" alt="" />
              </div>
            ))}
          </div>
        </div>
      </body>

      <div className="flex flex-col items-center overflow-x-scroll my-8">
        <div className="flex items-center gap-8 overflow-auto">
          <img className="h-24" src="https://i.kym-cdn.com/photos/images/newsfeed/001/365/242/652.gif" />
          <div className="flex flex-col gap-4">
            <div className="text-lg text-center font-bold md:text-2xl lg:text-4xl">bera walk - $bera</div>
            <div className="text-sm text-center md:text-lg lg:text-2xl">CDJkwHVSP4FQ5r22HL9mvrGbP8CuKDFo6pc1Yq2Dpump</div>
          </div>
          <img className="h-24" src="https://stickermaker.s3.eu-west-1.amazonaws.com/storage/uploads/sticker-pack/polar-bear-walking/sticker_3.gif?240d2a7d14817b054fe13cadde0524ff" />
        </div>
      </div>

      <div className="w-screen flex flex-col justify-center items-center sm:flex-row gap-4 py-8">
        <div className="flex flex-col w-[80%] max-w-md justify-between gap-6 sm:w-[40%]">
          <div className="text-lg text-center font-bold md:text-2xl lg:text-4xl">information</div>

          <a href="https://dexscreener.com/solana/CDJkwHVSP4FQ5r22HL9mvrGbP8CuKDFo6pc1Yq2Dpump" target="_blank" className="text-center">
            chart
          </a>
          <a href="https://pump.fun/CDJkwHVSP4FQ5r22HL9mvrGbP8CuKDFo6pc1Yq2Dpump" target="_blank" className="text-center">
            pump.fun
          </a>
        </div>
        <img className="w-[80%] sm:w-[30%] max-w-[400px]" src="https://media.tenor.com/kBIaWesiPywAAAAi/poley-polar-bear.gif" alt="" />
      </div>

      <div className="w-screen flex flex-col justify-center items-center sm:flex-row gap-4 py-8">
        <div className="flex flex-col w-full items-center">
        <img className="w-[60%] max-w-[269px]" src="https://i.redd.it/nehc56efu71c1.gif" alt="" />
          <div></div>
        </div>
        <div className="flex flex-col w-full items-center">
          <img className="w-[60%] max-w-[269px]" src="https://stickermaker.s3.eu-west-1.amazonaws.com/storage/uploads/sticker-pack/polar-bear-walking/sticker_4.gif?240d2a7d14817b054fe13cadde0524ff" alt="" />
          <div></div>
        </div>
      </div>

      <body className="py-2">
        <div className="slider">
          <div className="slide-track">
            {[...Array(40)].map((_, index) => (
              <div className="slide">
                <img className="w-40 aspect-auto" src="https://media.tenor.com/nOij19s2b8gAAAAi/polar-bear-walking.gif" alt="" />
              </div>
            ))}
          </div>
        </div>
      </body>
      

      {/*<div className="flex flex-wrap justify-center">
        <img className="w-[25%]" src="https://i.redd.it/ggi99c5eu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/fagn688eu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/bycw1xceu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/8m9ytgfeu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/kxrlinheu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/uz1kqijeu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/v736uhqeu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/edwhnoteu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/d5l9ibxeu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/v736uhqeu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/5vm7w58fu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/3u376xbfu71c1.gif" />
        <img className="w-[25%]" src="https://i.redd.it/1pxtkegfu71c1.gif" />
            </div>*/}
    </div>
  );
};

export default App;
